import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  useLocation,
  Redirect,
  BrowserRouter,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import NotFound from "../NotFound/index";
import Restaurants from "../Restaurants";
import Users from "../Users";
import Sidebar from "../Sidebar";
import CreateRestaurant from "../CreateRestaurant";
import Coupons from "../Coupons";
import CreateCoupon from "../CreateCoupon.js";
import CouponsRedeemed from "../CouponsRedeemed";
import RestaurantDetails from "../RestaurantDetails";
import CouponDetails from "../CouponDetails";
import RedeemedCouponsRestaurant from "../RedeemedCouponsRestaurant";
import EditRestaurant from "../EditRestaurant";
import EditCoupon from "../EditCoupon";

export const DashboardRoutes = (props) => {
  const history = useHistory();
 
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // console.log(user)
      console.log(user?.email,'routepage')
      if (!user) {
        return history.push("/");
      }
    });
  }, []);
  let location = useLocation();
  if (location.pathname == "/dashboard") {
    return <Redirect to="/dashboard/restaurants" />;
  }

  return (
    <div>
      <Sidebar />
      <div className="table">
        <Switch>
          <Route exact path="/dashboard/restaurants" component={Restaurants} />
          <Route exact path="/dashboard/users" component={Users} />
          <Route
            exact
            path="/dashboard/createrestaurant"
            component={CreateRestaurant}
          />
          <Route
            exact
            path="/dashboard/editrestaurant"
            component={EditRestaurant}
          />
          <Route exact path="/dashboard/coupons" component={Coupons} />
          <Route
            exact
            path="/dashboard/createcoupon"
            component={CreateCoupon}
          />
          <Route exact path="/dashboard/editcoupon" component={EditCoupon} />
          <Route
            exact
            path="/dashboard/couponsredeemed"
            component={CouponsRedeemed}
          />
          <Route
            exact
            path="/dashboard/redeemedcoupons"
            component={RedeemedCouponsRestaurant}
          />
          <Route
            exact
            path="/dashboard/restaurantdetails"
            component={RestaurantDetails}
          />

          <Route
            exact
            path="/dashboard/coupondetails"
            component={CouponDetails}
          />
        </Switch>
      </div>
    </div>
  );
};
