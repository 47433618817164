import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  Divider,
  Card, 
  Fab,
  Tooltip,
  TextField,
  makeStyles,
  Button,
  Breadcrumbs,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { addDocwithId, addSingleDoc, restaurantUser } from "../../NetworkCalls/firestoreCalls";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  GeoPoint
 } from "firebase/firestore";
import "./style.css";
import { collectionNames } from "../../Constants/firebaseCollections";


const useStyles = makeStyles((theme) => ({
  input: {
    borderBottom: "1px solid black",
  },
}));

function CreateRestaurant(props) {
  const classes = useStyles();
  const [image, setImage] = useState(null); 
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const auth = getAuth();
// console.log(loader)
  const inputImage = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const initialValues = {
    restaurantName: "",
    email: "",
    password: "",
    plotNo: "",
    streetName: "",
    areaName: "",
    cityName: "",
    countryName: "",
    postalCode: "",
    longitude: "",
    latitude: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    restaurantName: Yup.string().required("Required"),
    email: Yup.string().required("Required").email(),
    password: Yup.string().required("Required").min(6),
    plotNo: Yup.string().required("Required"),
    streetName: Yup.string().required("Required"),
    areaName: Yup.string().required("Required"),
    cityName: Yup.string().required("Required"),
    countryName: Yup.string().required("Required"),
    postalCode: Yup.string().required("Required"),
    longitude: Yup.number().required("Required").min(-90, 'Longitude must between -90 to 90').max(90, 'Longitude must between -90 to 90'),
    latitude: Yup.number().required("Required").min(-90, 'Latitude must between -90 to 90').max(90, 'Latitude must between -90 to 90'),
    description: Yup.string().required("Required"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // const restaurantUser = await createUserWithEmailAndPassword(
      //   auth,
      //   values.email,
      //   values.password
      // );
    setLoader(true);
      restaurantUser(values.email,values.password)
      .then(async(res)=>{
        // console.log(JSON.parse(res).code,'res')
        if(JSON.parse(res).code == 'auth/email-already-exists'){
          alert('Email already taken!')
          setLoader(false)
        }
        else{
    setLoader(true);
        const data = JSON.parse(res)
        let finalValues = {
          ...values,
          isActive: true,
          code: Math.floor(100000 + Math.random() * 900000),
          password: "",
          restaurantID: data.uid,
          longitude: values.longitude.toString(), 
          latitude: values.latitude.toString(),
          coordinates: new GeoPoint( values.latitude.toString(),values.longitude.toString())
        };
        console.log(finalValues,'finalValues')
        const response = await addDocwithId(
          collectionNames.restaurants,
          data.uid,
          finalValues   
        );
        resetForm();
        history.push({
          pathname: "/dashboard/restaurantdetails",
          state: finalValues,
        });
      }})
      .catch((e)=>{
      console.log(e,'e')
      })
    } catch (error) {
      console.log("in errors");
      swal({
        title: error?.result,
        text: error?.message,
        icon: "warning",
        dangerMode: true,
      });
      setErrors({ submit: error.message });
    }
    finally
    {
      setLoader(false);
    }
    
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <div>
          <Grid
            style={{ marginBottom: "10px" }}
            justifyContent="space-between"
            container
            spacing={24}
          >
            <Grid item>
              <Typography variant="h4" gutterBottom display="inline">
                Create Restaurant
              </Typography>
            </Grid>
          </Grid>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to="/dashboard/restaurants">Restaurants</Link>
            <Typography>Create New Restaurants</Typography>
          </Breadcrumbs>
          <Divider style={{ margin: "38px 3px" }} my={6} />

          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              {/* <Grid item xs={11} md={6} justifyContent="center" alignItems="flex-end">
          <div className="img-container">
            <img
              src={image ? image : placeholder}
              alt=""
              height="100%"
              width="100%"
            />
            <div className="upload-btn">
              <Tooltip title="Upload Photo">
                <Fab
                  onClick={() => {
                    inputImage.current.click();
                  }}
                  color="primary"
                  aria-label="add"
                >
                  <AddAPhoto />
                </Fab>
              </Tooltip>
            </div>
            <input
              ref={inputImage}
              style={{ display: "none" }}
              type="file"
              onChange={onImageChange}
              accept="image/*"
            />
          </div>
        </Grid> */}

              <Grid
                item
                xs={11}
                md={6}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <TextField
                  id="email"
                  type="text"
                  label="Email"
                  className="field"
                  color="primary"
                  inputProps={{ className: classes.input }}
                  name="email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="restaurant"
                  type="text"
                  label="Restaurant Name"
                  className="field"
                  color="primary"
                  autocomplete="off"
                  inputProps={{ className: classes.input }}
                  name="restaurantName"
                  value={values.restaurantName}
                  error={Boolean(
                    touched.restaurantName && errors.restaurantName
                  )}
                  helperText={touched.restaurantName && errors.restaurantName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  className="field"
                  color="primary"
                  inputProps={{ className: classes.input }}
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={6} xs={11}>
                <TextField
                  id="plotNo"
                  type="text"
                  label="Plot #"
                  className="field"
                  color="primary"
                  name="plotNo"
                  value={values.plotNo}
                  error={Boolean(touched.plotNo && errors.plotNo)}
                  helperText={touched.plotNo && errors.plotNo}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="streetName"
                  type="text"
                  label="Street Name"
                  className="field"
                  color="primary"
                  name="streetName"
                  value={values.streetName}
                  error={Boolean(touched.streetName && errors.streetName)}
                  helperText={touched.streetName && errors.streetName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="areaName"
                  type="text"
                  label="Area Name"
                  className="field"
                  color="primary"
                  name="areaName"
                  value={values.areaName}
                  error={Boolean(touched.areaName && errors.areaName)}
                  helperText={touched.areaName && errors.areaName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="cityName"
                  type="text"
                  label="City Name"
                  className="field"
                  color="primary"
                  name="cityName"
                  value={values.cityName}
                  error={Boolean(touched.cityName && errors.cityName)}
                  helperText={touched.cityName && errors.cityName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="countryName"
                  type="text"
                  label="Country Name"
                  className="field"
                  color="primary"
                  name="countryName"
                  value={values.countryName}
                  error={Boolean(touched.countryName && errors.countryName)}
                  helperText={touched.countryName && errors.countryName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="postalCode"
                  type="text"
                  label="Postal Code"
                  className="field"
                  color="primary"
                  name="postalCode"
                  value={values.postalCode}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="longitude"
                  type="number"
                  label="Longitude"
                  className="field"
                  color="primary"
                  name="longitude"
                  value={values.longitude}
                  error={Boolean(touched.longitude && errors.longitude)}
                  helperText={touched.longitude && errors.longitude}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="latitude"
                  type="number"
                  label="Latitude"
                  className="field"
                  color="primary"
                  name="latitude"
                  value={values.latitude}
                  error={Boolean(touched.latitude && errors.latitude)}
                  helperText={touched.latitude && errors.latitude}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              {/* <Grid item md={6} xs={12} className="hideOnMobile"></Grid> */}
              <Grid item md={6} xs={11}>
                <TextField
                  id="description"
                  type="text"
                  label="Description"
                  className="field"
                  color="primary"
                  name="description"
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} justifyContent="center">
              <Grid item md={6} xs={12}>
                {/* <Link
                  to={{ pathname: "/dashboard/restaurantdetails", state: data }}
                > */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="login-submit"
                  style={{ padding: 15, marginTop: 20 }}
                  disabled={loader}
                  // onClick={() => console.log(data)}
                >
                  {loader ? (
                    <CircularProgress color="primary" size={23} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                {/* </Link> */}
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Formik>
  );
}

export default CreateRestaurant;
