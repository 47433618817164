import React from "react";
import Routes from "./Routes/index";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: "#890008",
    },
  },
});

const App = (props) => {
  return (

    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
