import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { CircularProgress } from "@material-ui/core";

// import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { green, orange, red } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Restaurant as RestaurantIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import restaurant from "../../Assets/restaurant_logo.jpg";

import { spacing } from "@material-ui/system";
import { getAllDocs } from "../../NetworkCalls/firestoreCalls";
import { collectionNames } from "../../Constants/firebaseCollections";
import SearchBar from "../../Components/SearchBar";
import ConfirmModal from "../../Components/ConfirmModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: #fff;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function createData(id, name, area, city) {
  return { id, name, area, city };
}

const rows = [
  createData("1", "Kaybees", "Jouhar", "Karachi"),
  createData("2", "Kaybees", "Jouhar", "Karachi"),
  createData("3", "Kaybees", "Jouhar", "Karachi"),
  createData("4", "Kaybees", "Jouhar", "Karachi"),
  createData("5", "Kaybees", "Jouhar", "Karachi"),
  createData("6", "Kaybees", "Jouhar", "Karachi"),
  createData("7", "Kaybees", "Jouhar", "Karachi"),
  createData("8", "Kaybees", "Jouhar", "Karachi"),
  createData("9", "Kaybees", "Jouhar", "Karachi"),
  createData("10", "Kaybees", "Jouhar", "Karachi"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", alignment: "left", label: "S.No" },
  { id: "restaurantName", alignment: "left", label: "Name" },
  { id: "areaName", alignment: "left", label: "Area" },
  { id: "cityName", alignment: "left", label: "City" },
  { id: "totalSales", alignment: "left", label: "Total Sales" },
  { id: "actions", alignment: "left", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
            color="primary"
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const [searched, setSearched] = React.useState("");

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="primary" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <div
            style={{
              display: "flex",
              width: "300px",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1, marginRight: "10px" }}>
              <Typography variant="h6" id="tableTitle">
                Restaurants
              </Typography>
            </div>
            <div style={{ flex: 2 }}>
              <SearchBar
                label="Search Name"
                value={searched}
                onChange={(val) => {
                  setSearched(val);
                  props.onSearch(val);
                }}
              />
            </div>
          </div>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [fixedData, setFixedData] = React.useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const getData = () => {
    getAllDocs(collectionNames.restaurants).then(async (res) => {
      // console.log(res);
      let arr = res.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      const coupons = await getAllDocs(collectionNames.coupons);

      let arr1 = [];
      for (const item of arr || []) {
        const filteredCoupons = coupons.filter(
          (item1) => item1.restaurantID == item.restaurantID
        );
        let sum = 0;
        filteredCoupons.forEach((item2) => {
          sum += item2.totalSales;
        });
        arr1.push({ ...item, totalSales: sum });
      }
      setLoader(false);
      // console.log(arr1);
      setData(arr1);
      setFixedData(arr1);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleSearch = (val) => {
    console.log(val);
    if (!val) {
      setData(fixedData);
      return;
    }
    const filteredRows = fixedData.filter((row) => {
      return row.restaurantName?.toLowerCase().includes(val?.toLowerCase());
    });
    console.log(filteredRows);
    setData(filteredRows);
  };

  return (
    <div>
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Paper>
          <EnhancedTableToolbar
            onSearch={(val) => handleSearch(val)}
            numSelected={selected.length}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell> */}

                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.restaurantName}</TableCell>
                        <TableCell align="left">{row.areaName}</TableCell>
                        <TableCell align="left">{row.cityName}</TableCell>
                        <TableCell align="left">{row.totalSales}</TableCell>

                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            <Link
                              to={{
                                pathname: "/dashboard/editrestaurant",
                                state: row,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Tooltip title="Edit Restaurant">
                                <IconButton aria-label="details">
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: "/dashboard/coupons",
                                state: row,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Tooltip title="View Coupons">
                                <IconButton aria-label="details">
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: "/dashboard/restaurantdetails",
                                state: row,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Tooltip title="Restaurant Details">
                                <IconButton aria-label="details">
                                  <RestaurantIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}

const Restaurants = () => {
  return (
    <React.Fragment>
      {/* <Helmet title="Orders" /> */}

      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Restaurants
          </Typography>
        </Grid>
        <Grid item>
          <Link
            to="/dashboard/createrestaurant"
            style={{ textDecoration: "none" }}
          >
            <div>
              <Button variant="contained" color="primary">
                <AddIcon />
                Create New Restaurant
              </Button>
            </div>
          </Link>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Restaurants;
