import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Formik, getActiveElement } from "formik";
import * as Yup from "yup";
import { getSingleDoc, signIn } from "../../NetworkCalls/firestoreCalls";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

// import { blue } from "@material-ui/core/colors";
import "./style.css";
import { collectionNames } from "../../Constants/firebaseCollections";

const useStyles = makeStyles((theme) => ({
  input: {
    borderBottom: "1px solid black",
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = getAuth();

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user,'loginpage');
        const admin = await getSingleDoc(collectionNames.admin, user.uid);
        console.log(admin,'admin')
        if (admin.code == 400)
        {console.log('hello')}
        return history.push("/dashboard");
      }
    });
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      // console.log(values);
      setLoader(true);
      const user = await signIn(values.email, values.password);
      localStorage.setItem('id',user.uid)
      const isAdmin = await getSingleDoc(collectionNames.admin, user.uid);
      if (isAdmin.code == 400) {
        setErrors({
          email: "this user is not authenticated for admin",
          password: "this user is not authenticated for admin",
        });
        setLoader(false);
      } else {
        console.log(isAdmin);
        resetForm();
        setLoader(false);
        history.push({ pathname: "/dashboard" });
      }
      // console.log(user);
    } catch (err) {
      setLoader(false);
      console.log("In errors");
      console.log(err);
      setErrors({ email: err.code, password: err.code });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <div>
          <div className="header">
            <h1 className="login-heading">Administration</h1>
            <div className="logo-circle"></div>
          </div>

          <div className="login-container">
            <div className="login-box">
              <p className="login-text">Login</p>

              <form onSubmit={handleSubmit}>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  className="field"
                  color="primary"
                  inputProps={{ className: classes.input }}
                  name="email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  className="field"
                  color="#890008"
                  style={{ marginTop: "0.9rem" }}
                  inputProps={{ className: classes.input }}
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {/* <input type="text" placeholder="Email" className="field" /> <br />
            <input type="Password" placeholder="Password" className="field" /> */}
                {/* <p className="forgot">Forgot your password?</p> */}

                {/* <Link to="/dashboard" style={{ textDecoration: "none" }}> */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="login-submit"
                  style={{ padding: 15, marginTop: 20 }}
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress size={23} color="primary" />
                  ) : (
                    "Login"
                  )}
                </Button>
                {/* </Link> */}
              </form>
            </div>
            
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Login;
