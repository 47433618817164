import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Breadcrumbs,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { green, orange, red } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getMultipleDocs,
  getSingleDoc,
  getSubCollection,
} from "../../NetworkCalls/firestoreCalls";
import { collectionNames } from "../../Constants/firebaseCollections";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: #fff;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function createData(id, date, title, restaurant, address) {
  return { id, date, title, restaurant, address };
}

const rows = [
  createData("1", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("2", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("3", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("4", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("5", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("6", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("7", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("8", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("9", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
  createData("10", "2021/08/08", "Restaurant", "KayBees", "Karachi"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", alignment: "left", label: "S.No" },
  { id: "redemptionDate", alignment: "left", label: "Date of Redemption" },
  { id: "couponTitle", alignment: "left", label: "Title" },
  { id: "restaurantName", alignment: "left", label: "Restaurant" },
  { id: "amountsave", alignment: "left", label: "Amount" },
  { id: "address", alignment: "left", label: "Address" },
  //   { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="primary" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Coupons
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const location = useLocation();

  console.log(location.state);
  // useEffect(async () => {
  //   let arr = [];
  //   for (const item of location.state || []) {
  //     if (item) {
  //       const docRef = await getSingleDoc(
  //         collectionNames.coupons,
  //         item.couponId
  //       );
  //       const restaurantRef = await getSingleDoc(
  //         collectionNames.restaurants,
  //         docRef.restaurantID
  //       );
  //       arr.push({
  //         redemptionDate: item.redemptionDate,
  //         couponDetails: docRef,
  //         restaurantDetails: restaurantRef,
  //       });
  //     }
  //   }

  //   setLoader(false);
  //   let arr1 = arr.map((item, index) => {
  //     return { ...item, id: index + 1 };
  //   });
  //   console.log("in redeemed coupons ------>", arr1);
  //   setData(arr1);
  // }, []);
  useEffect(async () => {
    const redeemed = await getSubCollection(
      collectionNames.users,
      location.state,
      collectionNames.redeemedCouponsUser
    );
    // console.log(redeemed);
    let arr = [];
    for (const item of redeemed || []) {
      if (item) {
        const docRef = await getSingleDoc(
          collectionNames.coupons,
          item.couponID
        );
        const restaurantRef = await getSingleDoc(
          collectionNames.restaurants,
          docRef.restaurantID
        );
        arr.push({
          redemptionDate: item.redemptionDate,
          couponDetails: docRef,
          restaurantDetails: restaurantRef,
        });
      }
    }
    setData(arr);
    setLoader(false);
    console.log(arr);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div>
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                        onClick={() => console.log(data)}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          {`${new Date(row.redemptionDate).getFullYear()}/${
                            new Date(row.redemptionDate).getMonth() + 1
                          }/${new Date(row.redemptionDate).getDate()} `}
                        </TableCell>
                        <TableCell align="left">
                          {row.couponDetails.couponTitle}
                        </TableCell>
                        <TableCell align="left">
                          {row.restaurantDetails.restaurantName}
                        </TableCell>
                        <TableCell align="left">
                          {row.couponDetails.amountsave}
                        </TableCell>
                        <TableCell align="left">{`${row.restaurantDetails.streetName}, ${row.restaurantDetails.areaName}, ${row.restaurantDetails.cityName}`}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}

const CouponsRedeemed = () => {
  return (
    <React.Fragment>
      {/* <Helmet title="Orders" /> */}

      <Grid
        style={{ marginBottom: "10px" }}
        justify="space-between"
        container
        spacing={24}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Coupons Redeemed
          </Typography>
        </Grid>
        {/* <Grid item>
          <Link to="/dashboard/createcoupon" style={{ textDecoration: "none" }}>
            <div>
              <Button variant="contained" color="primary">
                <AddIcon />
                Create New Coupon
              </Button>
            </div>
          </Link>
        </Grid> */}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link to="/dashboard/users">Users</Link>
        <Typography>Coupons Redeemed</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CouponsRedeemed;
