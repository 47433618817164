import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDtDGO5yY1bJtYfSVGZRRtHp673cevec0g",
  authDomain: "plus-one-646fa.firebaseapp.com",
  projectId: "plus-one-646fa",
  storageBucket: "plus-one-646fa.appspot.com",
  messagingSenderId: "36064669319",
  appId: "1:36064669319:web:ff97109a212b73cbcb5222",
  measurementId: "G-X7DG9JN09R",
};

const app = initializeApp(firebaseConfig);
export default app;
