import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";

import coupon from "../../Assets/coupon_pic.png";
// import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  Breadcrumbs,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Fastfood,
  Edit as EditIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllDocs,
  getSubCollection,
} from "../../NetworkCalls/firestoreCalls";
import { collectionNames } from "../../Constants/firebaseCollections";
import ConfirmModal from "../../Components/ConfirmModal";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function createData(id, name, logo, desc) {
  return { id, name, logo, desc };
}

const rows = [
  createData("1", coupon, "$10", "A good Offer"),
  createData("2", coupon, "$10", "A good Offer"),
  createData("3", coupon, "$10", "A good Offer"),
  createData("4", coupon, "$10", "A good Offer"),
  createData("5", coupon, "$10", "A good Offer"),
  createData("6", coupon, "$10", "A good Offer"),
  createData("7", coupon, "$10", "A good Offer"),
  createData("8", coupon, "$10", "A good Offer"),
  createData("9", coupon, "$10", "A good Offer"),
  createData("10", coupon, "$10", "A good Offer"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", alignment: "left", label: "S.No" },
  { id: "coupon", alignment: "left", label: "Coupon" },
  { id: "amountsave", alignment: "left", label: "Offer" },
  { id: "totalSales", alignment: "left", label: "Value" },
  { id: "desc", alignment: "left", label: "Description" },
  { id: "actions", alignment: "left", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="primary" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Coupons
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);

  const location = useLocation();
  console.log(location.state);
  useEffect(() => {
    getAllDocs(collectionNames.coupons).then(async (res) => {
      const filteredData = res.filter(
        (doc) => location.state?.restaurantID == doc.restaurantID
      );
      let arr = filteredData.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      setLoader(false);
      // console.log(arr1);
      setData(arr);
    });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div>
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">
                          <img src={row.mediaURI} height="60px" width="60px" />
                        </TableCell>
                        <TableCell align="left">{row.amountsave}</TableCell>
                        <TableCell align="left">{row.totalSales}</TableCell>
                        <TableCell align="left">{`${row.description.substring(
                          0,
                          30
                        )}...`}</TableCell>

                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            <Link
                              to={{
                                pathname: "/dashboard/editcoupon",
                                state: row,
                              }}
                            >
                              <Tooltip title="Edit Coupon">
                                <IconButton aria-label="details">
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: "/dashboard/coupondetails",
                                state: {
                                  ...row,
                                  restaurantName: location.state.restaurantName,
                                  restaurantAddress: `${location.state.streetName}, ${location.state.areaName}, ${location.state.cityName}`,
                                },
                              }}
                            >
                              <Tooltip title="View Coupon Details">
                                <IconButton aria-label="details">
                                  <Fastfood />
                                </IconButton>
                              </Tooltip>
                            </Link>

                            <Link
                              to={{
                                pathname: "/dashboard/redeemedcoupons",
                                state: {
                                  ...row,
                                  redeemedCoupons:
                                    location.state.redeemedCoupons,
                                },
                              }}
                            >
                              <Tooltip title="View Redeemed Coupons">
                                <IconButton aria-label="redeemed">
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}

const Coupons = () => {
  return (
    <React.Fragment>
      {/* <Helmet title="Orders" /> */}

      <Grid
        style={{ marginBottom: "10px" }}
        justify="space-between"
        container
        spacing={24}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Coupons
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/dashboard/createcoupon" style={{ textDecoration: "none" }}>
            <div>
              <Button variant="contained" color="primary">
                <AddIcon />
                Create New Coupon
              </Button>
            </div>
          </Link>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link to="/dashboard/restaurants">Restaurants</Link>
        <Typography>Coupons</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Coupons;
