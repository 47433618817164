import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Card,
  Fab,
  Tooltip,
  TextField,
  makeStyles,
  Button,
  Breadcrumbs,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { Link, useLocation } from "react-router-dom";
import { AddAPhoto } from "@material-ui/icons";
import placeholder from "../../Assets/placeholder.png";

import "./style.css";
import { getSingleDoc } from "../../NetworkCalls/firestoreCalls";
import { collectionNames } from "../../Constants/firebaseCollections";

function CouponDetails(props) {
  const location = useLocation();
  console.log(location);

  const [restaurantDetails, setRestaurantDetails] = useState({});

  useEffect(async () => {
    const restaurantId = location?.state?.restaurantID;
    const restaurant = await getSingleDoc(
      collectionNames.restaurants,
      restaurantId
    );
    console.log(restaurant);
    setRestaurantDetails(restaurant);
  }, []);
  const data = [
    {
      label: "Coupon Title",
      value: location?.state?.couponTitle,
    },
    {
      label: "Cuisine",
      value: location?.state?.cuisine,
    },
    {
      label: "Description",
      value: location?.state?.description,
    },
    {
      label: "Diet",
      value: location?.state?.diet,
    },
    {
      label: "Amount Save",
      value: location?.state?.amountsave,
    },
    {
      label: "Discount",
      value: location?.state?.discount,
    },
    {
      label: "Validity Date",
      value: location.state.validity
        ? `${new Date(location.state.validity).getFullYear()}/${
            new Date(location.state.validity).getMonth() + 1
          }/${new Date(location.state.validity).getDate()}`
        : "01/01/2021",
    },
    {
      label: "Restaurant Name",
      value: restaurantDetails?.restaurantName,
    },
    {
      label: "Restaurant Address",
      value: `${restaurantDetails.streetName}, ${restaurantDetails.areaName}, ${restaurantDetails.cityName}`,
    },
  ];
  return (
    <div>
      <Grid
        style={{ marginBottom: "10px" }}
        justifyContent="space-between"
        container
        spacing={24}
      >
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Coupon Details
          </Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link
          to={{
            pathname: "/dashboard/coupons",
            state: location.state,
          }}
        >
          Coupons
        </Link>
        <Typography>Coupon Details</Typography>
      </Breadcrumbs>
      <Divider style={{ margin: "38px 3px" }} my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} justifyContent="center" alignItems="flex-end">
          <div className="img-container">
            <img
              src={
                location.state.mediaURI ? location.state.mediaURI : placeholder
              }
              alt=""
              height="100%"
              width="100%"
            />
          </div>
          <div style={{ padding: "30px 2px" }}>
            {data.map((item, index) => {
              return (
                <Typography
                  key={index}
                  style={{ padding: "5px", display: "flex" }}
                >
                  <p className="label">{item.label} </p> {item.value}
                </Typography>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default CouponDetails;
