import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  Divider,
  Card,
  Fab,
  Tooltip,
  TextField,
  makeStyles,
  Button,
  Breadcrumbs,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { Link, useLocation } from "react-router-dom";
import { AddAPhoto } from "@material-ui/icons";
import placeholder from "../../Assets/placeholder.png";

import "./style.css";

function RestaurantDetails(props) {
  const location = useLocation();
  console.log(location);
  return (
    <div>
      <Grid
        style={{ marginBottom: "10px" }}
        justifyContent="space-between"
        container
        spacing={24}
      >
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Restaurant Details
          </Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link to="/dashboard/restaurants">Restaurants</Link>
        <Typography>Restaurant Details</Typography>
      </Breadcrumbs>
      <Divider style={{ margin: "38px 3px" }} my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} justifyContent="center" alignItems="flex-end">
          {/* <div className="img-container">
            <img
              src={location.state.image ? location.state.image : placeholder}
              alt=""
              height="100%"
              width="100%"
            />
          </div> */}
          <div style={{ padding: "5px 2px" }}>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label"> Restaurant Name: </p>
              {location?.state?.restaurantName
                ? location?.state?.restaurantName
                : "Test Restaurant"}
            </Typography>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Description: </p>{" "}
              {location?.state?.description
                ? location?.state?.description
                : "Test Description"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Street #: </p>{" "}
              {location?.state?.streetName
                ? location?.state?.streetName
                : "Test Street Number"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Area Name: </p>{" "}
              {location?.state?.areaName
                ? location?.state?.areaName
                : "Test Area Name"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">City Name: </p>{" "}
              {location?.state?.cityName
                ? location?.state?.cityName
                : "Test City Name"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Postal Code: </p>{" "}
              {location?.state?.postalCode
                ? location?.state?.postalCode
                : "Test Postal Code"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Longitude </p>{" "}
              {location?.state?.longitude
                ? location?.state?.longitude
                : "Test Longitude"}
            </Typography>

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Latitude: </p>{" "}
              {location?.state?.latitude
                ? location?.state?.latitude
                : "Test Latitude"}
            </Typography>

            <div style={{ padding: "5px" }}>
           
              <QRCode
                // value={`http://facebook.github.io/${location.state.code}`}
                value={location?.state?.code?.toString()}
              />
            </div>
            {/* {console.log(location?.state?.code)} */}

            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Code: </p>{" "}
              {location?.state?.code ? location?.state?.code : "Test Latitude"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default RestaurantDetails;
