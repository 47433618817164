import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Fab,
  Tooltip,
  TextField,
  makeStyles,
  Button,
  Breadcrumbs,
  CircularProgress,
  Checkbox,
  Radio,
  FormGroup,
} from "@material-ui/core";
import swal from "sweetalert";
import { Formik, getActiveElement } from "formik";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { AddAPhoto } from "@material-ui/icons";
import placeholder from "../../Assets/placeholder.png";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { uploadMedia } from "../../NetworkCalls/mediaUpload";
import {
  getAllDocs,
  addDocwithId,
  addDocwithId1,
} from "../../NetworkCalls/firestoreCalls";

import { FormControlLabel } from "@material-ui/core";

import "./style.css";
import { collectionNames } from "../../Constants/firebaseCollections";

const useStyles = makeStyles((theme) => ({
  input: {
    borderBottom: "1px solid black",
  },
}));

function CreateCoupon(props) {

  const [selectedValue, setSelectedValue] = React.useState(false);
  const handleRadio = (event) => {
    setSelectedValue(event.target.value === "true" ? true : false);
  };
  const classes = useStyles();

  const [image, setImage] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [restaurantData, setRestaurantData] = useState([]);
  const [validity, setValidity] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(true)

  const history = useHistory();

  useEffect(async () => {
    const restaurants = await getAllDocs(collectionNames.restaurants);
    console.log(restaurants);
    setRestaurantData(restaurants);
  }, []);

  const initialValues = {
    couponTitle: "",
    restaurantID: "",
    description: "",
    diet: "",
    cuisine: "",
    amountsave: 0,
    discount: "",
    restaurantSale: 0,
    originalPrice: 0,
    exclusive: false
  };

  const validationSchema = Yup.object().shape({
    couponTitle: Yup.string().required("Required"),
    restaurantID: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    diet: Yup.string(),
    cuisine: Yup.string().required("Required"),
    amountsave: Yup.number().typeError('Must be a number').required("Required"),
    discount: Yup.string().required("Required"),
    restaurantSale: Yup.number().typeError('Must be a number').required("Required"),
    originalPrice: Yup.number().typeError('Must be a number').required("Required"),
  });

  const inputImage = useRef(null);

  const onImageChange = async (event) => {
    console.log(event.target.files[0]);
    setImageLoader(true);
    if (event.target.files && event.target.files[0]) {
      uploadMedia(event.target.files[0]).then((res) => {
        setImageLoader(false);
        setImage(res);
        setImageError(false);
      });

      // setImage(URL.createObjectURL(event.target.files[0]));
    } else {
      setImageLoader(false);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (image) {
      setImageError(false);
      try {
        setLoader(true);
        const finalData = {
          ...values,
          totalSales: 0,
          premium: checked,
          mediaURI: image.url,
          isActive: isActive,
          validity: Date.parse(validity),
          originalPrice: parseInt(values.originalPrice),
          restaurantSale: parseInt(values.restaurantSale),
          amountsave: parseInt(values.amountsave),
          discount: values.discount,
          exclusive: selectedValue

        };
        const response = await addDocwithId1(
          collectionNames.coupons,
          finalData
        );
        console.log(response);
        resetForm();
        setImage(null);
        setLoader(false);
        history.push({
          pathname: "/dashboard/coupondetails",
          state: finalData,
        });
      } catch (error) {
        console.log("in errors");
        swal({
          title: error?.result,
          text: error?.message,
          icon: "warning",
          dangerMode: true,
        });
        setErrors({ submit: error.message });
      }
    } else {
      setImageError(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <div>
          <Grid
            style={{ marginBottom: "10px" }}
            justifyContent="space-between"
            container
            spacing={24}
          >
            <Grid item>
              <Typography variant="h4" gutterBottom display="inline">
                Create Coupons
              </Typography>
            </Grid>
          </Grid>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to="/dashboard/restaurants">Restaurants</Link>
            <Link to="/dashboard/coupons">Coupons</Link>
            <Typography>Create New Coupon</Typography>
          </Breadcrumbs>
          <Divider style={{ margin: "38px 3px" }} my={6} />

          {/* <Grid container spacing={6}>
        
      </Grid> */}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid
                item
                xs={11}
                md={6}
                justifyContent="center"
                alignItems="flex-end"
              >
                <div className="img-container">
                  {imageLoader ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <img
                      src={image ? image.url : placeholder}
                      alt=""
                      height="100%"
                      width="100%"
                    />
                  )}

                  <div className="upload-btn">
                    <Tooltip title="Upload Photo">
                      <Fab
                        onClick={() => {
                          inputImage.current.click();
                        }}
                        color="primary"
                        aria-label="add"
                      >
                        <AddAPhoto />
                      </Fab>
                    </Tooltip>
                  </div>
                  <input
                    ref={inputImage}
                    style={{ display: "none" }}
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                </div>
                {imageError ? (
                  <p style={{ color: "red", marginTop: "5px" }}>Required</p>
                ) : null}
              </Grid>

              <Grid
                item
                xs={11}
                md={6}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Restaurant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Diet"
                    name="restaurantID"
                    value={values.restaurantID}
                    error={Boolean(touched.restaurantID && errors.restaurantID)}
                    helperText={touched.restaurantID && errors.restaurantID}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {restaurantData.map((item, index) => (
                      <MenuItem key={index} value={item.restaurantID}>
                        {item.restaurantName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="title"
                  type="text"
                  label="Title"
                  className="field"
                  color="primary"
                  name="couponTitle"
                  value={values.couponTitle}
                  error={Boolean(touched.couponTitle && errors.couponTitle)}
                  helperText={touched.couponTitle && errors.couponTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={11} md={6}>
                <MobileDatePicker
                  disablePast
                  cancelText={false}
                  label="Validity Date"
                  value={validity}
                  onChange={(newValue) => {
                    setValidity(newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="description"
                  type="text"
                  multiline
                  label="Description"
                  className="field"
                  color="primary"
                  // style={{ padding: 0, borderBottom: "1px solid #333" }}
                  name="description"
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                // value={details}
                // onChange={(e) => setDetails(e.target.value)}
                />
              </Grid>

              <Grid item md={6} xs={11}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Diet (optional)</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Diet"
                    name="diet"
                    value={values.diet}
                    error={Boolean(touched.diet && errors.diet)}
                    helperText={touched.diet && errors.diet}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  // value={diet}
                  // onChange={(e) => setDiet(e.target.value)}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="Halal">Halal</MenuItem>
                    <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                    <MenuItem value="Kosher">Kosher</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} xs={11}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Cuisine</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Cuisine"
                    name="cuisine"
                    value={values.cuisine}
                    error={Boolean(touched.cuisine && errors.cuisine)}
                    helperText={touched.cuisine && errors.cuisine}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  // value={cuisine}
                  // onChange={(e) => setCuisine(e.target.value)}
                  >
                    <MenuItem value="Chinese">Chinese</MenuItem>
                    <MenuItem value="Indian">Indian</MenuItem>
                    <MenuItem value="Arabic">Arabic</MenuItem>
                    <MenuItem value="Mediterranean">Mediterranean</MenuItem>
                    <MenuItem value="Italian">Italian</MenuItem>
                    <MenuItem value="International">International</MenuItem>
                    <MenuItem value="Fast Food">Fast Food</MenuItem>
                    <MenuItem value="Asian">Asian</MenuItem>
                    <MenuItem value="Continental">Continental</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="savedamount"
                  type="text"
                  label="Saved Amount"
                  className="field"
                  color="primary"
                  name="amountsave"
                  value={values.amountsave}
                  error={Boolean(touched.amountsave && errors.amountsave)}
                  helperText={touched.amountsave && errors.amountsave}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={11} md={6}>
                <TextField
                  id="discount"
                  type="text"
                  label="Discount"
                  className="field"
                  color="primary"
                  name="discount"
                  value={values.discount}
                  error={Boolean(touched.discount && errors.discount)}
                  helperText={touched.discount && errors.discount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={6} xs={11}>
                <TextField
                  id="restaurantSale"
                  type="text"
                  multiline
                  label="Restaurant Sale"
                  className="field"
                  color="primary"
                  name="restaurantSale"
                  value={values.restaurantSale}
                  error={Boolean(
                    touched.restaurantSale && errors.restaurantSale
                  )}
                  helperText={touched.restaurantSale && errors.restaurantSale}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={6} xs={11}>
                <TextField
                  id="originalPrice"
                  type="text"
                  label="Original Price"
                  className="field"
                  color="primary"
                  name="originalPrice"
                  value={values.originalPrice}
                  error={Boolean(touched.originalPrice && errors.originalPrice)}
                  helperText={touched.originalPrice && errors.originalPrice}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>






              <Grid style={{ paddingLeft: 25 }} md={6} xs={11}>
                <label style={{ fontSize: 12, color: 'gray' }}>Coupon</label>
                <br></br>
                <Radio color="primary"
                  checked={selectedValue === true}
                  name="coupon" value={true} onChange={handleRadio}> </Radio>Exclusive
                <br></br>
                <Radio color="primary"
                  checked={selectedValue === false} name="coupon" value={false} onChange={handleRadio}></Radio>Non-Exclusive

              </Grid>
            </Grid>
            <Grid item md={6} xs={11}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"

                      checked={checked}
                      onChange={(e) => {
                        // console.log(e.target.checked);
                        setChecked(e.target.checked);
                      }}
                    />
                  }
                  label="is Premium?"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isActive}
                      onChange={(e) => {
                        // console.log(e.target.checked);
                        setIsActive(e.target.checked);
                      }}
                    />
                  }
                  label="is Active"
                />
              </FormGroup>
            </Grid>
            <Grid container spacing={6} justifyContent="center">
              <Grid item md={6} xs={12}>
                {/* <Link
                  to={{ pathname: "/dashboard/coupondetails", state: data }}
                > */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="login-submit"
                  style={{ padding: 15, marginTop: 20 }}
                  disabled={loader}
                // onClick={() => {}}
                >
                  {loader ? (
                    <CircularProgress color="primary" size={23} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                {/* </Link> */}
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Formik>
  );
}

export default CreateCoupon;
