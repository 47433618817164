export const uploadMedia = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = new FormData();
      data.append("file", body);

      const response = await fetch(
        "https://us-central1-plus-one-646fa.cloudfunctions.net/UploadImage",
        {
          method: "POST",
          body: data,
        }
      );

      const jsonResponse = await response.json();
      console.log(jsonResponse);
      resolve(jsonResponse);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
