import app from "../firebaseConfig";
// import { getFirestore, getDocs, collection } from "firebase/firestore/lite";
import {
  doc,
  getDoc,
  query,
  where,
  getFirestore,
  getDocs,
  collection,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collectionNames } from "../Constants/firebaseCollections";

const db = getFirestore(app);
const auth = getAuth();

export const getAllDocs = async (collectionName) => {
  const col = collection(db, collectionName);
  const snapshot = await getDocs(col);

  const list = snapshot.docs.map((doc) => {
    return { ...doc.data(), selfId: doc.id };
  });
  return list;
};

export const getSingleDoc = async (collectionName, docName) => {
  const docRef = doc(db, collectionName, docName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { code: 400, message: "document not found" };
  }
};

export const updateCoupon = async (collectionName, docName, body) => {
  try {
    const docRef = doc(db, collectionName, docName);
    await updateDoc(docRef, body);
    return { code: 200, message: "document updated successfully" };
  } catch (err) {
    return { code: 400, message: "document not found" };
  }
};

export const updateRestaurant = async (collectionName, docName, body) => {
  try {
    const docRef = doc(db, collectionName, docName);
    await updateDoc(docRef, body);
    // console.log(docName);
    const coupons = await getAllDocs(collectionNames.coupons);
    const filteredCoupons = coupons.filter(
      (item) => item.restaurantID == docName
    );
    for (const item of filteredCoupons) {
      await updateCoupon(collectionNames.coupons, item.couponID, {
        isActive: body?.isActive,
      });
    }
    return { code: 200, message: "document updated successfully" };
  } catch (err) {
    console.log(err);
    return { code: 400, message: "document not found" };
  }
};

export const getMultipleDocs = async (collectionName, query) => {
  const q = query(
    collection(db, collectionName),
    where("couponID", "==", query)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => doc.data());
  return list;
};

export const addSingleDoc = async (collectionName, body) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), body);
    return {
      result: "success",
      message: `document added successfully with id = ${docRef.id}`,
    };
  } catch (err) {
    return { result: "failed", message: err };
  }
};

export const addDocInSubCollection = async (
  parentCollectionName,
  docName,
  childCollectionName,
  body
) => {
  try {
    const couponRef = doc(
      db,
      parentCollectionName,
      docName,
      childCollectionName
    );
    const docRef = await addDoc(couponRef, body);
    return {
      result: "success",
      message: `document added successfully with id = ${docRef.id}`,
    };
  } catch (err) {
    return { result: "failed", message: err };
  }
};

export const getSubCollection = async (
  parentCollectionName,
  docName,
  childCollectionName
) => {
  const collectionRef = collection(
    db,
    parentCollectionName,
    docName,
    childCollectionName
  );
  const docSnap = await getDocs(collectionRef);
  const list = docSnap.docs.map((doc) => doc.data());
  return list;
};

export const addDocwithId = async (collectionName, docName, body) => {
  // console.log(collectionName, docName, body);
  try {
    const docRef = await setDoc(doc(db, collectionName, docName), body);

    return {
      result: "success",
      message: `document added successfully`,
    };
  } catch (err) {
    console.log(err);
    return { result: "failed", message: err };
  }
};

export const addDocwithId1 = async (collectionName, body) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), body);
    const couponRef = doc(db, collectionName, docRef.id);
    const updateCoupon = await updateDoc(couponRef, { couponID: docRef.id });

    return {
      result: "success",
      message: `document added successfully with id = ${docRef.id}`,
    };
  } catch (err) {
    return { result: "failed", message: err };
  }
};

export const signIn = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      resolve(userCredentials.user);
    } catch (err) {
      reject({ code: err.code, message: err.message });
    }
  });
};

export const restaurantUser = (email,password) =>{
  return new Promise((resolve,reject)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "email": email,
      "password": password
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://us-central1-plus-one-646fa.cloudfunctions.net/RestaurantUser", requestOptions)
      .then(response => response.text())
      .then(result => {resolve(result)})
      .catch(error => reject(error));
  })
 
}