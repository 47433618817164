import React from "react";

function NotFound(props) {
  return (
    <div>
      <h1>Error 404, requested page not found!</h1>
    </div>
  );
}

export default NotFound;
